import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";
import { Summary } from "../../../common/summary/Summary";

export const Access = ({ content, children }: WizardContentProps) => (
  <>
    <Header
      title={(isGeneral(content) && content.consentTitle) || undefined}
      collapsed={true}
    />
    <section className="privacy">
      <h2>Hva innebærer det å delta?</h2>
      <br />
      <Summary />
      <p>
        Deltakelse innebærer også at vi innhenter følgende informasjon fra Statistisk sentralbyrå og fylkeskommunen om alle som deltar: Alder, kjønn, bostedskommune, fødeverdensdel, resultater fra nasjonale prøver på 8. og 9. trinn., ungdomsskole avgangskarakterer, fravær på ungdomsskolen, søking og deltakelse i videregående opplæring, utdanningsnivå og -type, yrkesdeltakelse, inntekt, ukentlig arbeidstid, yrkeskategori, jobbsøking/deltakelse på arbeidsmarkedstiltak, frem til 2034. I tillegg innhenter vi dine foreldres inntektsnivå, utdanningsnivå og fødeverdensdel
      </p>
      <p>
        Mens prosjektet pågår, lagrer vi ditt personnummer og telefonnummer adskilt fra øvrig data. Du må benytte bankID for å logge på RØST.
      </p>
      <p>
        På neste side skal vi se mer på hvordan vi ivaretar ditt personvern.
      </p>
      {children()}
    </section>
  </>
);
