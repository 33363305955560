import React from "react";

export const PrivacyOverview = (args: { backButton: () => void }) => (
  <section className="settingsPage">
    <h1>Samtykke</h1>
    {args.backButton()}
    <p>
      <em>Dette er det du har samtykket til da du registrerte deg i RØST:</em>
    </p>
    <h2>Hva innebærer det å delta?</h2>
    <p>
      I RØST vil vi be deg svare på tre spørreundersøkelser. Den første får du i dag og den siste får du om ni uker. Hver spørreundersøkelse tar 10-15 minutter. Spørsmålene handler om hvilke tanker du har rundt læring, motivasjon og om hvordan du har det. Vi vil også spørre deg om hva du tenker om Oppfølgingstjenesten.
    </p>
    <p>
      RØST har fire moduler over fire uker. Hver modul tar ca. 15-30 minutter å gjennomføre. Du vil få SMS- påminnelser når en ny modul er klar.
    </p>
    <h2>
      Dersom du fullfører spørreskjema og moduler vil du tjene poeng. Disse poengene kan veksles inn i tre gavekort med en samlet verdi på 1000kr. Det første får du etter modul 1, det andre etter modul 4 og det siste etter det avsluttende spørreskjemaet.
    </h2>
    <p>
      Alle opplysningene om deg vil bli avidentifisert – ditt fødselsnummer vil
      erstattes med en tilfeldig kode. Det betyr at ingen vil kunne vite hvem du
      er, eller hva akkurat du har svart i dette prosjektet. Vi vil bare bruke
      opplysningene om deg til formålene vi har beskrevet tidligere.
    </p>
    <p>
      Vi oppbevarer og behandler alle opplysningene om deg strengt konfidensielt
      og i samsvar med personvernregelverket.
    </p>
    <p>
      Alle deltakere vil motta melding med lenke til nyhetsbrev om prosjektet
      hvert 5. år frem til personopplysninger i prosjektet er slettet.
    </p>
    <h2>
      Hva skjer med opplysningene om deg når vi avslutter forskningsprosjektet?
    </h2>
    <p>Opplysningene slettes når prosjektet avsluttes i 2040.</p>
    <h2>Det er frivillig å delta</h2>
    <p>
      Det er frivillig å delta i prosjektet. Det vil ikke ha noen negative konsekvenser for deg hvis du ikke vil delta eller senere velger å be om å få dine opplysninger slettet.
    </p>
    <p>
      Det vil ikke ha noen negative konsekvenser for deg hvis du ikke vil delta,
      eller senere velger å trekke deg.
    </p>
    <p>
      Ditt valg om deltakelse og dine svar vil ikke påvirke din saksbehandling
      hos NAV.
    </p>
    <h2>Dine rettigheter</h2>
    <p>
      Så lenge du kan identifiseres i datamaterialet, har du rett til innsyn,
      samt å få rettet eller slettet personopplysninger om deg. Du har også rett
      til å sende klage til Datatilsynet. Se kontaktopplysninger under.
    </p>
    <h2>Rett til å behandle personopplysninger</h2>
    <p>
      NSD (Norsk senter for forskningsdata) har vurdert at behandlingen av
      personopplysninger i dette prosjektet er i samsvar med
      personvernregelverket.
    </p>
    <h2>Kontakt oss for spørsmål</h2>
    <p>
      <strong>Hilde Sandvold</strong> (forskningskoordinator) ved Universitetet
      i Stavanger:{" "}
      <a href="mailto:hilde.sandvold@uis.no">hilde.sandvold@uis.no</a> eller
      telefon/SMS 952 57 868.
    </p>
    <p>
      <strong>Rolf Jegervatn</strong> (personvernombud):{" "}
      <a href="mailto:rolf.jegervatn@uis.no">rolf.jegervatn@uis.no</a> eller
      telefon 971 77 749
    </p>
  </section>
);
