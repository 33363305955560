import React from "react";
import classNames from "classnames";

import { getFormattedDate } from "../../../../utilities";
import { useSelector } from "../../../../hooks";

interface Props {
  title?: string;
  dropped?: boolean;
  description?: string;
  index: number;
  stage: "done" | "locked" | "open";
}

const SESSION_INTERVALS = [604800, 604800, 604800, 604800, 2419200, 604800]; // Should match the ones in backend

export const CardInner = (args: Props) => {
  const { dropped, title, description, index, stage } = args;
  const started = useSelector((state) => state.progress.started) || 0;
  const daysLeft =
    (started +
      SESSION_INTERVALS.reduce(
        (sum, interval, i) => (i <= index ? sum + interval : sum),
        0
      ) -
      parseInt((new Date().getTime() / 1000).toFixed(0))) /
    60 /
    60 /
    24;

  return (
    <p
      className={classNames("title", index === 0 && "first")}
      data-count={index}
    >
      {title || ""}
      <span key={index} className="subtitle typo-undertekst">
        {description || ""}.
        {!dropped &&
          (stage === "done" ? (
            <i> {index === 0 ? "200" : index === 5 ? "400" : "100"} poeng!</i>
          ) : stage === "open" ? (
            <i>
              {" "}
              {daysLeft.toFixed()} dag{daysLeft.toFixed() === "1" ? "" : "er"}{" "}
              igjen
            </i>
          ) : (
            <i>
              {" "}
              Åpner{" "}
              {getFormattedDate(
                (started +
                  SESSION_INTERVALS.reduce(
                    (sum, interval, i) => (i < index ? sum + interval : sum),
                    0
                  )) *
                  1000
              )}
            </i>
          ))}
        {dropped && <i> Utløpt!</i>}
      </span>
    </p>
  );
};
