import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";

export const Terms = ({ content, children }: WizardContentProps) => (
  <>
    <Header
      title={(isGeneral(content) && content.consentTitle) || undefined}
      collapsed={true}
    />
    <section className="privacy">
      <h2>Det er frivillig å delta</h2>
      <p>
        Det er frivillig å delta i prosjektet. Det vil ikke ha noen negative konsekvenser for deg hvis du ikke vil delta eller senere velger å be om å få dine opplysninger slettet.
      </p>
      <br />
      <p>
        Hva gir oss rett til å behandle personopplysninger om deg?
      </p>
      <p>
        Dersom du samtykker til å delta, kan vi behandle opplysninger om deg til vitenskapelig forskning, fordi forskningsprosjektet er vurdert å være i allmennhetens interesse.
      </p>
      <br />
      <h2>Dine rettigheter</h2>
      <p>
        Så lenge vi kan finne deg i datamaterialet, har du rett til å be om innsyn, og til retting og sletting av opplysninger vi behandler om deg, og rett til å protestere. Du har også rett til å klage til Datatilsynet om hvordan vi behandler dine opplysninger. Se kontaktopplysninger under. Sikt (Kunnskapssektorens tjenesteleverandør) har vurdert at behandlingen av personopplysninger i dette prosjektet er i samsvar med personvernregelverket. Videre har behandlingsansvarlig institusjon Universitetet i Stavanger vurdert selve web-applikasjonen
      </p>
      <br />
      <h2>Kontakt oss for spørsmål </h2>
      <p>
        <strong>Elin Svensen</strong> (forskningskoordinator) ved
        Universitetet i Stavanger:{" "}
        <a href="mailto:elin.svensen@uis.no">elin.svensen@uis.no</a> eller
        telefon 518 32 185.
      </p>
      <p>
        <strong>Rolf Jegervatn</strong> (personvernombud):{" "}
        <a href="mailto:rolf.jegervatn@uis.no">rolf.jegervatn@uis.no</a> eller
        telefon 971 77 749
      </p>
      <p>
        Dersom du senere ønsker å trekke deg kan du sende e-post til{" "}
        <a href="mailto:hjelp@rost.app">hjelp@rost.app</a> eller
        til <a href="mailto:elin.svensen@uis.no">elin.svensen@uis.no</a>. Oppgi ditt telefonnummer og at du ønsker å trekke deg. All informasjon om samtykke vil være tilgjengelig i innstillingen til RØST under samtykke.
      </p>
      {children()}
    </section>
  </>
);
