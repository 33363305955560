import React from "react";
import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";

export const Privacy = ({ content, children }: WizardContentProps) => (
  <>
    <Header
      title={(isGeneral(content) && content.consentTitle) || undefined}
      collapsed={true}
    />
    <section className="privacy">
      <h2>
        Ditt personvern – hvordan vi oppbevarer og bruker opplysninger om deg
      </h2>
      <p>
        Ditt fødselsnummer, ungdomsskole og bostedskommune vil erstattes med en tilfeldig kode og kun prosjektleder vet hva din kode er. Siden personnummer lagres avskilt fra de øvrige data vil forskerne som jobber med data ikke vite hvem du er eller hva du har svart. 
      </p>
      <p>
        Vi vil bare bruke opplysningene om deg til formålene vi har beskrevet tidligere. Oppfølgingstjenesten har ikke tilgang til informasjon om din deltakelse eller til alle de opplysningene du oppgir i RØST. Oppfølgingstjenesten vil kun motta anonymiserte data med svar på spørsmål om Oppfølgingstjenesten, knyttet til kommune og et tilfeldig løpenummer for hver deltaker.
      </p>
      <p>
        Forskningsgruppen ved UiS oppbevarer og behandler alle opplysningene om deg strengt konfidensielt og i samsvar med personvernregelverket.
      </p>
      <p>
        Alle deltakere vil motta SMS (til det oppgitte telefonnummeret) med lenke til nyhetsbrev om prosjektet hvert 5. år frem til personopplysninger i prosjektet er slettet.
      </p>
      <br />
      <h2>
        Hva skjer med opplysningene om deg når vi avslutter
        forskningsprosjektet?
      </h2>
      <p>Opplysningene slettes når prosjektet avsluttes i 2040.</p>
      {children()}
    </section>
  </>
);
